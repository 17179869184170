import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button, IconButton, Typography, Link } from 'aloe/src';
import { getCSRFToken } from '~/shared/csrfToken';

import '@fontsource/merriweather';

interface RevealPasswordButtonProps {
  revealPassword: boolean;
  setRevealPassword: React.Dispatch<React.SetStateAction<boolean>>;
}

function RevealPasswordButton({ revealPassword, setRevealPassword }: RevealPasswordButtonProps) {
  return (
    <IconButton
      icon={revealPassword ? 'eye' : 'eye-off'}
      size={20}
      onClick={() => setRevealPassword(!revealPassword)}
      aria-label="Reveal password"
    />
  );
}

interface LoginProps {
  aura: string;
  loginRoute: string;
  resetPasswordRoute: string;
  signUpRoute: string;
}

export function LogIn(props: LoginProps) {
  const [seePassword, setSeePassword] = useState(false);
  const { register, watch } = useForm();
  const email = watch('email');
  const password = watch('password');

  const passwordInputProps = {
    type: seePassword ? 'text' : 'password',
    endAdornment: <RevealPasswordButton revealPassword={seePassword} setRevealPassword={setSeePassword} />,
  };

  function SignIn() {
    return (
      <div className="tw-mx-auto tw-mb-5 tw-mt-10 tw-h-auto tw-w-4/5 tw-text-center">
        <Typography variant="display-3" marginBottom>
          Welcome to
          <br />
          Brilliant Black Minds.
        </Typography>

        <Form.Group className="tw-mb-4">
          <Form.FloatingInput
            data-testid="email"
            label="Email"
            type="email"
            id="email"
            {...register('email', { required: true })}
          />
          <Form.FloatingInput
            data-testid="password"
            label="Password"
            id="password"
            {...passwordInputProps}
            {...register('password', { required: true })}
          />
        </Form.Group>

        <Button
          variant="primary"
          type="submit"
          className="tw-mb-4"
          data-testid="sign-in"
          fullWidth
          disabled={!email || !password}
        >
          Sign In
        </Button>

        <div className="tw-mb-4 tw-text-left">
          <Link href={props.resetPasswordRoute}>Forgot password?</Link>
        </div>

        <div className="tw-mb-4 tw-text-left">
          Don't have a login? <Link href={props.signUpRoute}>Create one now.</Link>
        </div>
      </div>
    );
  }

  return (
    <Form
      className="tw-flex tw-h-screen tw-justify-center tw-overflow-auto tw-bg-[length:100%_100%]"
      method="POST"
      action={props.loginRoute}
      style={{ backgroundImage: `url(${props.aura})`, backgroundColor: '#113333' }}
    >
      <div className="tw-m-auto tw-w-[450px] tw-rounded tw-bg-white">{SignIn()}</div>

      <input type="hidden" name="authenticity_token" value={getCSRFToken()} />
      <input type="hidden" name="user[email]" value={email} />
      <input type="hidden" name="user[password]" value={password} />
    </Form>
  );
}
